.p-selector {
    margin-bottom: 0.5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.p-title {
    padding-left: 60px;
    padding-right: 20px;
    font-size: 35px;
    font-weight: 800;
    text-decoration: none;
    align-items: baseline;
    color: black;
}

.p-text-select {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
    font-weight: 800;
    text-decoration: none;
    align-items: baseline;
    color: black;
  }

  .p-b-active {
    background-color: white;
    color:black;
    font-size: 20px;
    font-weight: 800;
    padding: 0;
    margin: 0;
  }
  
  .p-b-unactive {
    background-color: white;
    color:gray;
    font-size: 20px;
    font-weight: 800;
    padding: 0;
    margin: 0;
  }

@media screen and (max-width: 65em) {

    .p-selector {
        position: fixed;
        width: 100vw;
        height: 40px;
        bottom: 35px;
        background-color: white;
        padding-left: 10px;

        display: flex;
        flex-direction: row;
        justify-content: left;
    }

    .p-languages {
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        align-self: center;
    }

    .p-title {
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 22px;
        font-weight: 600;
        text-decoration: none;
        align-items: baseline;
        color: black;
    }

    .p-text-select {
        /* padding-left: 10px; */
        /* padding-right: 10px; */
        font-size: 18px;
        font-weight: 800;
        text-decoration: none;
        align-items: baseline;
        align-self: center;
        color: black;
      }

      .p-b-active {
        font-size: 18px;
      }
      
      .p-b-unactive {
        font-size: 18px;
      }

}