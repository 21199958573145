.a {
    display: flex;
    align-items: center;
}

.a-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    height: 80%;
}

.a-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: static;
    height: 80%;
}

.a-card {
    padding-left: 5%;
    height: 90%;
}

.a-card-carousel {
    width: 90%;
    padding-left: 5%;
    height: 80%;
}

.a-img {
    width: 100%;
    height: 90%;
    object-fit: cover;
    display: flex;
}

.a-img-phone {
    display: none;
}

.a-title {
    font-weight: 800;
    text-align: left;
}

.a-text {
    font-weight: 300;
    margin: 15px 0;
    width: 90%;
    text-align: left;
}

@media screen and (max-width: 480px) {
    .a {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 10%;
    }

    .a-left {
        width: 100%;
        margin-top: 2vh;
    }

    .a-img {
        display: none;
    }

    .a-img-phone {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: flex;
    }

    .a-card-carousel {
        display: none;
    }

    .a-card {
        display: flex;
        padding-right: 5%;
    }

    .a-right {
        display: flex;
        flex-direction: column;
        padding: 5px;
    }

    .a-title {
        font-size: 25px;
        margin-bottom: 2vh;
    }

    .a-text {
        margin-bottom: 1vh;
        font-size: 16px;
        width: 95%;
    }
}