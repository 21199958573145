hr {
    width: 90%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.o {
 display: flex;
 flex-wrap: wrap;
 justify-content: space-evenly;
}

.o-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
}

.o-left {
 display: flex;
 flex-direction: column;
 justify-content: center;
 justify-self: center;
 width: 45%;
 height: auto;
}

.o-right {
 display: flex;
 flex-direction: column;
 justify-content:center;
 align-self: center;
 width: 45%;
 height: auto;
}

.o-card {
 padding-left: 5%;
}

.o-img {
 width: 70%;
 height: auto;
 object-fit: cover;
 display: flex;
 flex-direction: column;
 align-self: center;
}

.o-img-shown-phone {
 display: none;
}

.o-title {
 font-weight: 550;
 margin-top: 2%;
 margin-bottom: 2%;
}

.o-line {
 margin: 2% 0;
 text-align: left;
}

.o-text {
 font-weight: 400;
 font-size: 17px;
 margin: 5px 0;
 width: 90%;
}

.o-tags {
    font-weight: 600;
   }


@media screen and (max-width: 480px) {
 .o{
  flex-direction: column;
  text-align: center;
  margin-bottom: 10%;
 }

 .o-left {
  width: 100%;
 }

 .o-img {
  display: none;
}

 .o-img-hidden-phone {
  display: none ;
 }

 .o-img-shown-phone {
  display: flex;
  width: 90%;
  object-fit: cover;
 }

 .o-right{
  width: 90%;
 }

 .o-title{
  font-size: 20px;
 }

 .o-text{
  font-size: 15px;
  text-align: left;
 }

 .o-tags {
    align-content: center;
    width: 90%;
   }

}
