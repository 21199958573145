.c {
  display: flex;
  align-items: center;
  padding-top: 2%;
}

.c-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  height: 100%;
}

.c-right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.c-card {
  width: 80%;
  height: 100%;
}

.c-title {
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 2%;
}

.c-info {
  padding-bottom: 3%;
}

.c-desc {
  margin: 2vh 0 0;
}

.c-info-item {
  margin: 2vh 0;
  align-items: center;
  display: flex;
}

.c-icon {
  width: 3vh;
  height: 3vh;
  margin-right: 2vh;
}

.c-img {
  width: 90%;
  height: 900%;
  object-fit: cover;
}

form {
  margin-top: 20px;
  flex-direction: column;
  padding-right: 15px;
}

input {
  width: 90%;
  height: 45px;
  border: none;
  border-bottom: 1px solid black;
}

textarea {
  width: 90%;
  margin-top: 10px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid black;
}

button {
  border: none;
  padding: 10px;
  color: white;
  background-color: black;
  margin-top: 10px;
  font-weight: 700;
}

@media screen and (max-width: 480px) {
  .c {
    flex-direction: column;
    margin-bottom: 10%;
  }

  .c-wrapper {
    flex-direction: column;
  }

  .c-left {
    flex-direction: column;
    width: 80%;
  }

  .c-right {
    flex-direction: column;
    margin-left: 4vh;
  }

  .c-card {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .c-img {
    width: 100%;
  }

  .c-title {
    margin-top: 15px;
  }
}