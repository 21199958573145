.hr {
  width: 100%;
  margin: 0;
}

.n {
  margin-top: 5px;
  height: 50px;
  background-color: white;
}

.n-list {
  display: flex;
  flex-direction: row;
}

.n-link {
  display: flex;
  align-items: end;
  padding-bottom: 5px;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 2%;
  width: 75%;
}

.n-languages {
  display: flex;
  flex-direction: row;
  padding: 0 2% 0 2%;
}

.n-text {
  padding-left: 6%;
  font-size: 20px;
  font-weight: 800;
  text-decoration: none;
  color: black;
}

.n-text-underline {
  text-decoration: underline;
  padding-left: 6%;
  font-size: 20px;
  font-weight: 800;
  align-items: baseline;
  color: black;
}

.n-logo {
  margin-left: 50px;
  display: flex;
  height: 45px;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}

.n-title {
  padding-top: 5px;
  padding-left: 2%;
  font-size: 28px;
  font-weight: 800;
  text-decoration: none;
  align-items: baseline;
  color: black;
}

.n-icon {
  display: none;
  position: relative;
  right: 0;
}

.n-icon-insta {
  display: flex;
  height: 30px;
  width: auto;
  align-items: baseline;
}

.n-text-lang {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 20px;
  font-weight: 800;
  text-decoration: none;
  align-items: baseline;
  color: black;
}

.n-b-active {
  background-color: white;
  color:black;
  font-size: 20px;
  font-weight: 800;
  padding: 0;
  margin: 0;
}

.n-b-unactive {
  background-color: white;
  color:gray;
  font-size: 20px;
  font-weight: 800;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 65em) {
  .hr {
    display: none;
  }

  .n {
    position: fixed;
    width: 100vw;
    height: 40px;
    bottom: 0;
    margin: 0;
  }

  .n-link {
    display: none;
  }

  .n-link-open {
    display: flex;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    margin-right: 0;
    margin-left: 10px;
    flex-direction: column;
    background-color: white;
    padding: 1rem 2rem;
    height: 95%;
    align-content: flex-end;

    transition: 0.2s ease-out;
  }

  .n-languages {
    padding-top: 15px;
    padding-left: 15px;
    display: flex;
    flex-direction: row;
    align-self: center;
  }

  .n-title {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 22px;
    font-weight: 600;
    text-decoration: none;
    align-items: baseline;
    color: black;
    z-index: 100;
  }

  .n-logo {
    margin-left: 10px;
    height: 30px;
  }

  .n-icon {
    display: flex;
    position: absolute;
    padding-top: 0.5rem;
    right: 1rem;
    color: black;
    height:80% ;
  }

  .n-icon-insta {
    padding-left: 40px;
    padding-top: 15px;
  }

  .n-text {
    padding-left: 40px;
    padding-top: 15px;
  }

  .n-text-underline {
    padding-left: 40px;
    padding-top: 15px;
    text-decoration: underline ;
  }
}